import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Typography is defined as the science of style, appearance, and structure of fonts that aim to provide convenience and aesthetics to the reader. Effective typography depends not only on the content but also on the presentation visual of the typography itself, starting from the font type, font size and line height. You can chek out the guideline for more detail description.`}</p>
    <div className="divi" />
    <h2>{`Heading`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Hero section, Title content or component, Subtitle of title`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Props Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Line Height`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading1'>{`Heading 1`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading2'>{`Heading 2`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading3'>{`Heading 3`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading4'>{`Heading 4`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading5'>{`Heading 5`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading6'>{`Heading 6`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26dp`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Control the font size of an element using the `}<inlineCode parentName="p">{`heading{size}`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`android: textAppearance = "?attr/heading1";
android: textAppearance = "?attr/heading2";
android: textAppearance = "?attr/heading3";
android: textAppearance = "?attr/heading4";
android: textAppearance = "?attr/heading5";
android: textAppearance = "?attr/heading6";
`}</code></pre>
    <h3>{`Heading 1`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="YourHeading1" parent="TextAppearance.MaterialComponents.Headline1">
    <item name="fontFamily">@font/montserrat_bold</item>
    <item name="android:fontFamily">@font/montserrat_bold</item>
    <item name="android:textSize">@dimen/dimen_34sp</item>
    <item name="lineHeight">@dimen/dimen_48dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="heading1">@style/YourHeading1</item>
    ...
</style>
`}</code></pre>
    <h3>{`Heading 2`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="YourHeading2" parent="TextAppearance.MaterialComponents.Headline2">
    <item name="fontFamily">@font/montserrat_bold</item>
    <item name="android:fontFamily">@font/montserrat_bold</item>
    <item name="android:textSize">@dimen/dimen_28sp</item>
    <item name="lineHeight">@dimen/dimen_42dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="heading2">@style/YourHeading2</item>
    ...
</style>
`}</code></pre>
    <h3>{`Heading 3`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="YourHeading3" parent="TextAppearance.MaterialComponents.Headline3">
    <item name="fontFamily">@font/montserrat_bold</item>
    <item name="android:fontFamily">@font/montserrat_bold</item>
    <item name="android:textSize">@dimen/dimen_24sp</item>
    <item name="lineHeight">@dimen/dimen_36dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="heading3">@style/YourHeading3</item>
    ...
</style>
`}</code></pre>
    <h3>{`Heading 4`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="YourHeading4" parent="TextAppearance.MaterialComponents.Headline4">
    <item name="fontFamily">@font/montserrat_bold</item>
    <item name="android:fontFamily">@font/montserrat_bold</item>
    <item name="android:textSize">@dimen/dimen_22sp</item>
    <item name="lineHeight">@dimen/dimen_32dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="heading4">@style/YourHeading4</item>
    ...
</style>
`}</code></pre>
    <h3>{`Heading 5`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="YourHeading5" parent="TextAppearance.MaterialComponents.Headline5">
    <item name="fontFamily">@font/montserrat_bold</item>
    <item name="android:fontFamily">@font/montserrat_bold</item>
    <item name="android:textSize">@dimen/dimen_20sp</item>
    <item name="lineHeight">@dimen/dimen_28dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="heading5">@style/YourHeading5</item>
    ...
</style>
`}</code></pre>
    <h3>{`Heading 6`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="YourHeading6" parent="TextAppearance.MaterialComponents.Headline6">
    <item name="fontFamily">@font/montserrat_bold</item>
    <item name="android:fontFamily">@font/montserrat_bold</item>
    <item name="android:textSize">@dimen/dimen_18sp</item>
    <item name="lineHeight">@dimen/dimen_26dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="heading6">@style/YourHeading6</item>
    ...
</style>
`}</code></pre>
    <div className="divi" />
    <h2>{`Body`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Description, Paragraph, Button, Text Field, Tabs, etc`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Props Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Line Height`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeReguler'>{`Large Reguler`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodyLargeRegular`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeSemibold'>{`Large Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodyLargeSemiBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeBold'>{`Large Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodyLargeBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeItalic'>{`Large Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodyLargeItalic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallReguler'>{`Small Reguler`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodySmallRegular`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallSemibold'>{`Small Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodySmallSemiBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallBold'>{`Small Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodySmallBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallItalic'>{`Small Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodySmallItalic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Control the font size of an element using the `}<inlineCode parentName="p">{`bodyLarge{Variant}`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`android: textAppearance = "?attr/bodyLargeRegular";
android: textAppearance = "?attr/bodyLargeSemiBold";
android: textAppearance = "?attr/bodyLargeBold";
android: textAppearance = "?attr/bodyLargeItalic";
android: textAppearance = "?attr/bodySmallRegular";
android: textAppearance = "?attr/bodySmallSemiBold";
android: textAppearance = "?attr/bodySmallBold";
android: textAppearance = "?attr/bodySmallItalic";
`}</code></pre>
    <h3>{`Body Large`}</h3>
    <h4>{`Body Large Regular`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="BodyLargeRegular" parent="TextAppearance.MaterialComponents.Body1">
    <item name="fontFamily">@font/montserrat_regular</item>
    <item name="android:fontFamily">@font/montserrat_regular</item>
    <item name="android:textSize">@dimen/dimen_18sp</item>
    <item name="lineHeight">@dimen/dimen_24dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="bodyLargeRegular">@style/BodyLargeRegular</item>
    ...
</style>
`}</code></pre>
    <h4>{`Body Large Medium`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="BodyLargeMedium" parent="TextAppearance.MaterialComponents.Body1">
    <item name="fontFamily">@font/montserrat_medium</item>
    <item name="android:fontFamily">@font/montserrat_medium</item>
    <item name="android:textSize">@dimen/dimen_18sp</item>
    <item name="lineHeight">@dimen/dimen_24dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="bodyLargeMedium">@style/BodyLargeMedium</item>
    ...
</style>
`}</code></pre>
    <h4>{`Body Large SemiBold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="BodyLargeSemiBold" parent="TextAppearance.MaterialComponents.Body1">
    <item name="fontFamily">@font/montserrat_semibold</item>
    <item name="android:fontFamily">@font/montserrat_semibold</item>
    <item name="android:textSize">@dimen/dimen_18sp</item>
    <item name="lineHeight">@dimen/dimen_24dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="bodyLargeSemiBold">@style/BodyLargeSemiBold</item>
    ...
</style>
`}</code></pre>
    <h4>{`Body Large Bold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="BodyLargeBold" parent="TextAppearance.MaterialComponents.Body1">
    <item name="fontFamily">@font/montserrat_bold</item>
    <item name="android:fontFamily">@font/montserrat_bold</item>
    <item name="android:textSize">@dimen/dimen_18sp</item>
    <item name="lineHeight">@dimen/dimen_24dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="bodyLargeBold">@style/BodyLargeBold</item>
    ...
</style>
`}</code></pre>
    <h4>{`Body Large Italic`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="BodyLargeItalic" parent="TextAppearance.MaterialComponents.Body1">
    <item name="fontFamily">@font/montserrat_italic</item>
    <item name="android:fontFamily">@font/montserrat_italic</item>
    <item name="android:textSize">@dimen/dimen_18sp</item>
    <item name="lineHeight">@dimen/dimen_24dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="bodyLargeItalic">@style/BodyLargeItalic</item>
    ...
</style>
`}</code></pre>
    <h3>{`Body Small`}</h3>
    <h4>{`Body Small Regular`}</h4>
    <p>{`Control the font size of an element using the bodySmall{Variant} token`}</p>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="BodySmallRegular" parent="TextAppearance.MaterialComponents.Body2">
    <item name="fontFamily">@font/opensans_regular</item>
    <item name="android:fontFamily">@font/opensans_regular</item>
    <item name="android:textSize">@dimen/dimen_14sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="bodySmallRegular">@style/BodySmallRegular</item>
    ...
</style>
`}</code></pre>
    <h4>{`Body Small Medium`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="BodySmallMedium" parent="TextAppearance.MaterialComponents.Body2">
    <item name="fontFamily">@font/opensans_medium</item>
    <item name="android:fontFamily">@font/opensans_medium</item>
    <item name="android:textSize">@dimen/dimen_14sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="bodySmallMedium">@style/BodySmallMedium</item>
    ...
</style>
`}</code></pre>
    <h4>{`Body Small SemiBold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="BodySmallSemiBold" parent="TextAppearance.MaterialComponents.Body2">
    <item name="fontFamily">@font/opensans_semibold</item>
    <item name="android:fontFamily">@font/opensans_semibold</item>
    <item name="android:textSize">@dimen/dimen_14sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="bodySmallSemiBold">@style/BodySmallSemiBold</item>
    ...
</style>
`}</code></pre>
    <h4>{`Body Small Bold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="BodySmallBold" parent="TextAppearance.MaterialComponents.Body2">
    <item name="fontFamily">@font/opensans_bold</item>
    <item name="android:fontFamily">@font/opensans_bold</item>
    <item name="android:textSize">@dimen/dimen_14sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="bodySmallBold">@style/BodySmallBold</item>
    ...
</style>
`}</code></pre>
    <h4>{`Body Small Italic`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="BodySmallItalic" parent="TextAppearance.MaterialComponents.Body2">
    <item name="fontFamily">@font/opensans_italic</item>
    <item name="android:fontFamily">@font/opensans_italic</item>
    <item name="android:textSize">@dimen/dimen_14sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="bodySmallItalic">@style/BodySmallItalic</item>
    ...
</style>
`}</code></pre>
    <div className="divi" />
    <h2>{`Caption`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Description, Tags, Chips, Badge, etc`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Props Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Line Height`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionLargeReguler'>{`Large Reguler`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionLargeRegular`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionLargeSemibold'>{`Large Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionLargeSemiBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionLargeBold'>{`Large Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionLargeBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionLargeItalic'>{`Large Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionLargeItalic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallReguler'>{`Small Reguler`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionSmallRegular`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallSemibold'>{`Small Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionSmallSemiBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallBold'>{`Small Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionSmallBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallItalic'>{`Small Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionSmallItalic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Control the font size of an element using the `}<inlineCode parentName="p">{`captionLarge{Variant}`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`android: textAppearance = "?attr/captionLargeRegular";
android: textAppearance = "?attr/captionLargeSemiBold";
android: textAppearance = "?attr/captionLargeBold";
android: textAppearance = "?attr/captionLargeItalic";
android: textAppearance = "?attr/captionSmallRegular";
android: textAppearance = "?attr/captionSmallSemiBold";
android: textAppearance = "?attr/captionSmallBold";
android: textAppearance = "?attr/captionSmallItalic";
`}</code></pre>
    <h3>{`Caption Large`}</h3>
    <h4>{`Caption Large Regular`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="CaptionLargeRegular" parent="TextAppearance.MaterialComponents.Caption">
    <item name="fontFamily">@font/opensans_regular</item>
    <item name="android:fontFamily">@font/opensans_regular</item>
    <item name="android:textSize">@dimen/dimen_12sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="captionLargeRegular">@style/CaptionLargeRegular</item>
    ...
</style>
`}</code></pre>
    <h4>{`Caption Large SemiBold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="CaptionLargeSemiBold" parent="TextAppearance.MaterialComponents.Caption">
    <item name="fontFamily">@font/opensans_semibold</item>
    <item name="android:fontFamily">@font/opensans_semibold</item>
    <item name="android:textSize">@dimen/dimen_12sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="captionLargeSemiBold">@style/CaptionLargeSemiBold</item>
    ...
</style>
`}</code></pre>
    <h4>{`Caption Large Bold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="CaptionLargeBold" parent="TextAppearance.MaterialComponents.Caption">
    <item name="fontFamily">@font/opensans_bold</item>
    <item name="android:fontFamily">@font/opensans_bold</item>
    <item name="android:textSize">@dimen/dimen_12sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="captionLargeBold">@style/CaptionLargeBold</item>
    ...
</style>
`}</code></pre>
    <h4>{`Caption Large Italic`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="CaptionLargeItalic" parent="TextAppearance.MaterialComponents.Caption">
    <item name="fontFamily">@font/opensans_italic</item>
    <item name="android:fontFamily">@font/opensans_italic</item>
    <item name="android:textSize">@dimen/dimen_12sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="captionLargeItalic">@style/CaptionLargeItalic</item>
    ...
</style>
`}</code></pre>
    <h3>{`Caption Small`}</h3>
    <h4>{`Caption Small Regular`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="CaptionSmallRegular" parent="TextAppearance.MaterialComponents.Caption">
    <item name="fontFamily">@font/montserrat_regular</item>
    <item name="android:fontFamily">@font/montserrat_regular</item>
    <item name="android:textSize">@dimen/dimen_10sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="captionSmallRegular">@style/CaptionSmallRegular</item>
    ...
</style>
`}</code></pre>
    <h4>{`Caption Small SemiBold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="CaptionSmallSemiBold" parent="TextAppearance.MaterialComponents.Caption">
    <item name="fontFamily">@font/montserrat_semibold</item>
    <item name="android:fontFamily">@font/montserrat_semibold</item>
    <item name="android:textSize">@dimen/dimen_10sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="captionSmallSemiBold">@style/CaptionSmallSemiBold</item>
    ...
</style>
`}</code></pre>
    <h4>{`Caption Small Bold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="CaptionSmallBold" parent="TextAppearance.MaterialComponents.Caption">
    <item name="fontFamily">@font/montserrat_bold</item>
    <item name="android:fontFamily">@font/montserrat_bold</item>
    <item name="android:textSize">@dimen/dimen_10sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="captionSmallBold">@style/CaptionSmallBold</item>
    ...
</style>
`}</code></pre>
    <h4>{`Caption Small Italic`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"typography.xml\"",
        "title": "\"typography.xml\""
      }}>{`<style name="CaptionSmallItalic" parent="TextAppearance.MaterialComponents.Caption">
    <item name="fontFamily">@font/montserrat_italic</item>
    <item name="android:fontFamily">@font/montserrat_italic</item>
    <item name="android:textSize">@dimen/dimen_10sp</item>
    <item name="lineHeight">@dimen/dimen_18dp</item>
    <item name="android:textStyle">normal</item>
</style>
`}</code></pre>
    <p>{`Register on your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="captionSmallItalic">@style/CaptionSmallItalic</item>
    ...
</style>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      